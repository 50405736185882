<template>
  <div class="detail-bdi" v-if="purchaseBatchDetail" :class="{'detail-bdi-row': showType === 'row', 'detail-bdi-column': showType === 'column'}">
    <div class="detail-list">
      <div class="t-info-text" v-if="purchaseMethodBatchDetailList.length > 0">该笔采购关联采购方式</div>
      <div style="display: flex;">
        <div class="budget-info-2" v-for="(v, index) in purchaseMethodBatchDetailList" :key="index">
          <div class="info-text-2">采购方式ID：{{v.code}}</div>
          <div class="info-text-2">职能科室：{{v.busOrgName}}</div>
          <div class="info-text-2">采购方方式：{{v.method}}</div>
        </div>
      </div>
    </div>
    <div>
      <div class="t-info-text">审批记录</div>
      <record :record-list="purchaseBatchDetail && purchaseBatchDetail.statusActionRecord ? purchaseBatchDetail.statusActionRecord : []"></record>
    </div>
  </div>
  <div v-else style="margin: 20px;">无预算</div>
</template>

<script>
import record from './record'

import {
  purchaseBatchDetailsRequest,
  purchaseMethodBatchDetailsRequest
} from '../../../../api'

export default {
  components: {
    record
  },
  props: {
    id: { type: Number, defualt: null },
    showType: {
      type: String,
      default: 'row'
    }
  },
  watch: {
    id: {
      deep: true,
      handler () {
        this.loadData()
      },
      immediate: true
    }
  },
  data () {
    return {
      purchaseBatchDetail: [],
      purchaseMethodBatchDetailList: []
    }
  },
  methods: {
    async loadData () {
      this.purchaseBatchDetail = null
      this.purchaseMethodBatchDetailList = []
      if (!this.id) {
        return
      }
      let datas = await purchaseBatchDetailsRequest.get({
        ids: this.id,
        statusActionRecord: 1
      })
      this.purchaseBatchDetail = datas.length > 0 ? datas[0] : null
      if (this.purchaseBatchDetail && this.purchaseBatchDetail.purchaseMethodBatchDetailIds) {
        this.purchaseMethodBatchDetailList = await purchaseMethodBatchDetailsRequest.get({
          ids: this.purchaseBatchDetail.purchaseMethodBatchDetailIds
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.detail-bdi-row {
  flex-direction: column;
}
.detail-bdi-column {
  flex-direction: row;
}
.detail-bdi {
  display: flex;
  margin: 10px 0;
  .t-info-text {
    font-weight: 600;
    padding: 0 10px;
  }
  .budget-info-2 {
    padding: 5px 10px 0;
    margin-bottom: 10px;
    .info-text-2 {
      font-size: 12px;
      white-space:nowrap;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
      margin-right: 10px
    }
    i {
      color: #FB6D8F;
      padding-left: 10px;
    }
  }
  .p-chart {
    height: 180px;
    display: flex;
    .chart-item {
      width: 150px;
      height: 100%;
    }
  }
}
</style>